import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

// const baseUrl = 'http://localhost:13131/api/payments';
// const baseUrlCards = 'http://localhost:13131/api/cards';

const baseUrl = 'https://apps.helvitech.com/api/payments';
const baseUrlCards = 'https://apps.helvitech.com/api/cards';

// const baseUrl = 'http://apps.rexiagroup.com:13131/api/payments';
// const baseUrlCards = 'http://apps.rexiagroup.com:13131/api/cards';
// const baseUrl = 'https://apps.helvitech.com:13131/api/payments';
// const baseUrlCards = 'https://apps.helvitech.com:13131/api/cards';


@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  private headers: HttpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Access-Control-Allow-Origin', '*');

  constructor(private http: HttpClient) { }

  // Payments
  getAll(): Observable<any> {
    return this.http.get(`${baseUrl}/findAll`, {headers: this.headers});
  }

  // Recent Payments
  getRecentPayments(): Observable<any> {
      return this.http.get(`${baseUrl}/findAllRecent`, {headers: this.headers});
  }
  
  get(id): Observable<any> {
    return this.http.get(`${baseUrl}/${id}`, {headers: this.headers});
  }

  create(data): Observable<any> {
    return this.http.post(baseUrl, data, {headers: this.headers});
  }

  update(id, data): Observable<any> {
    return this.http.put(`${baseUrl}/${id}`, data, {headers: this.headers});
  }

  delete(id): Observable<any> {
    return this.http.delete(`${baseUrl}/${id}`, {headers: this.headers});
  }

  deleteAll(): Observable<any> {
    return this.http.delete(baseUrl, {headers: this.headers});
  }

  findByClientNames(clientNames): Observable<any> {
    return this.http.get(`${baseUrl}?clientNames=${clientNames}`, {headers: this.headers});
  }
  
  // Cards
  getAllCards(): Observable<any> {
    return this.http.get(`${baseUrlCards}/findAll`, {headers: this.headers});
  }

  // Cards
  getRecentCards(): Observable<any> {
      return this.http.get(`${baseUrlCards}/findAllRecent`, {headers: this.headers});
  }
  
  getCard(id): Observable<any> {
    return this.http.get(`${baseUrlCards}/${id}`, {headers: this.headers});
  }

  createCard(data): Observable<any> {
    return this.http.post(baseUrlCards, data, {headers: this.headers});
  }

  updateCard(id, data): Observable<any> {
    return this.http.put(`${baseUrlCards}/${id}`, data, {headers: this.headers});
  }

  deleteCard(id): Observable<any> {
    return this.http.delete(`${baseUrlCards}/${id}`, {headers: this.headers});
  }

  deleteAllCards(): Observable<any> {
    return this.http.delete(baseUrlCards, {headers: this.headers});
  }

  findCardsByClientNames(clientNames): Observable<any> {
    return this.http.get(`${baseUrlCards}?clientNames=${clientNames}`, {headers: this.headers});
  }

}

